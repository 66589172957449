import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import { getLandingPage } from "../generated-graphql-types";

export const query = graphql`
  query getLandingPage {
    site {
      siteMetadata {
        title
      }
    }
    landingPage: file(name: { eq: "landing" }) {
      name
      markdown: childMarkdownRemark {
        fields {
          collection
        }
        html
      }
    }
  }
`;

export default function Landing(props: { data: getLandingPage }) {
  const { site, landingPage } = props.data;

  return (
    <Layout>
      <Helmet title={site.siteMetadata.title} />
    </Layout>
  );
}
